body.iframe-page-body {
  display: flex;
  flex-direction: row-reverse;
}
body.has-sidebar .wrapper-for-iframe-page {
  width: calc(100vw - 600px);
  max-width: 95%;
}
body.has-sidebar #viewport-preview {
  /* max-width: 100%; */
}

body.static-page {
  position: relative;
  overflow: hidden;
}
body.static-page #viewport-preview {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transform: none;
}
/* #static-page{
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
} */
.sidebars-wrapper > div {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000 !important;
  background: #fff;
  padding: 10px;
  max-width: 480px;
}
.wrapper-for-iframe-page {
  /* max-width: 100%; */
  width: 98%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 20px;
  gap: 50px;
}
body.has-sidebar {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.sidebars-wrapper > div {
  top: 0;
  z-index: 1000 !important;
  padding: 10px;
  max-width: 480px;
}
body.has-sidebar .sidebars-wrapper > div {
  background: #fff;
}

.sidebar-menu__close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 1;
}

.sidebar-menu__close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 1;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.popup {
  /* Style your popup here */
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* .pencil-icon {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  z-index: 1000;
} */

/* Popper styles (popup container) */

div.base-Popper-root {
  position: fixed !important;
  top: 300px !important;
  min-width: 300px;
  max-width: 500px;
  width: 100%;
  max-height: 60vh;
  overflow-y: auto;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-style-popup {
  background-color: white; /* Or any color you prefer */
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 90%; /* Adjust width as needed */
  max-height: 80vh; /* 80% of the viewport height */
  overflow-y: auto; /* Enable vertical scrolling */
  position: fixed; /* Popup position */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust position */
  z-index: 1000; /* Ensure it's on top */
}

.custom-style-popup h3 {
  margin-top: 0;
  font-size: 40px;
}

.custom-style-popup > div {
  margin-bottom: 20px; /* Space between sections */
}

.custom-style-popup label {
  display: block; /* Make label take the entire line */
  margin-bottom: 5px; /* Space between label and input */
}

.custom-style-popup input[type="text"] {
  width: 100%; /* Full width */
  padding: 8px;
  margin-bottom: 10px; /* Space after input */
  border: 1px solid #ccc; /* Border color */
  border-radius: 4px; /* Rounded corners */
}

.custom-style-popup button {
  background-color: #0052da; /* Button color */
  color: white; /* Text color */
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.custom-style-popup button:hover {
  background-color: #003dab; /* Darker on hover */
}

.version-manager {
  font-family: Arial, sans-serif;
  max-width: 600px;
  margin: auto;
}

.version-table {
  display: flex;
  flex-direction: column;
}

.version-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.version-key {
  flex: 1;
  padding: 5px 10px;
  background-color: #f2f2f2;
  border: 1px solid #ddd;
}

.version-input {
  flex: 2;
}

.version-input input,
.new-key-input input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.version-action {
  margin-left: 10px;
}

.clone-button,
.add-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.clone-button:hover,
.add-button:hover {
  background-color: #45a049;
}

/* STYLES FOR POSITIONING EDIT BUTTONS ABSOLUTE ON THE PAGE START */

/* .edit-add-action-btn {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 4px;
  color: #333;
  position: absolute;
  z-index: 1000;
} */

.expert-member__position,
footer.footer.footer-background,
section.prices.price-background,
section.resources.resources-background,
section.faq.faq--background,
section.about.about-background,
section.review.client-background,
section.offer.offer-background,
section.statistics.statistics-background,
section.brands.brands-background,
section.experts.expert-background,
section.hero.main-page-background,
.offer-title-size {
  position: relative;
}

header.header .header__container .pencil-icon {
  top: 0;
  left: 100px;
}

/* STYLES FOR POSITIONING EDIT BUTTONS ABSOLUTE ON THE PAGE END */

/* STYLES FOR THE 3D CUBE ANIMATED ON SCROLL */
.scroll-section {
  position: relative;
}

.cube-wrapper {
  position: absolute;
  top: 50%;
  right: -80px;
  transform: translateY(-50%);
  z-index: 1;
}

.cube-container {
  width: 100px; /* Adjusted width */
  height: 100px; /* Adjusted height */
  perspective: 400px; /* Adjusted perspective */
}

.cube {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
}

.face {
  position: absolute;
  width: 100px; /* Adjusted width */
  height: 100px; /* Adjusted height */
  line-height: 100px; /* Adjusted line-height */
  text-align: center;
  background-size: cover;
}

.front,
.back,
.right,
.left,
.top,
.bottom {
  background: rgba(0, 126, 235, 0.5);
  background-image: url("../public/img/cube.jpg");
  background-size: cover;
}

.front {
  transform: translateZ(50px); /* Adjusted translateZ */
}

.back {
  transform: rotateY(180deg) translateZ(50px); /* Adjusted translateZ */
}

.right {
  transform: rotateY(90deg) translateZ(50px); /* Adjusted translateZ */
}

.left {
  transform: rotateY(-90deg) translateZ(50px); /* Adjusted translateZ */
}

.top {
  transform: rotateX(90deg) translateZ(50px); /* Adjusted translateZ */
}

.bottom {
  transform: rotateX(-90deg) translateZ(50px); /* Adjusted translateZ */
}

.MuiColorInput-ColorSpace.css-z5z22r {
  /* display: none; */
}
.MuiColorInput-ColorSpace.css-z5z22r {
  cursor: url("./assets/pipette.webp"), auto;
  background-color: transparent;
}

[data-section-toggle].hidden {
  display: none;
}
[data-remove].hidden {
  display: none;
}
[data-remove].hidden.preview-hidden {
  display: block;
  opacity: 0.8;
}
